<template>
	<div class="coming-soon-page" style="background-image: url(/static/images/bg-cs.jpg);background-position: center center; background-size: cover;">
		<div class="container">
			<!-- Search -->
			<div class="row">
				<div class="col-lg-8 mx-auto">
					<img src="/static/images/logo-white.png" width="150" alt="">
					<h3>We will be right back</h3>
					<!-- Countdown -->
					<div id="countdown" class="mb-4"></div>
					<!-- Countdown / End -->
					<br>
					<div class="mb-4">
						<div class="form-group mb-4">
							<div class="input-group input-group-alternative">
								<input class="form-control" placeholder="Email" type="text">
							</div>
						</div>
						<button class="btn btn-primary">Notify Me</button>
					</div>
				</div>
			</div>
			<!-- Search Section / End -->
		</div>
	</div>
</template>
<script>
export default {
}
</script>
